import React, {useState} from 'react';
import logo from './logo.svg';
import './App.css';
import Home from "./pages/Home";

function App() {
  return (
    <Home />
  );
}

export default App;
